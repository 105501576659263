.Drawer-container {
    position: absolute;
    z-index: 999;
    width: 100vmin;
    height: 100vmin;
    pointer-events: none;
    overflow: hidden;
}

.Drawer-itemParent {
    position: absolute;
    margin: 10vmin;
    width: 80vmin;
    height: 80vmin;
    transition: all 0.3s;
    pointer-events: none;
}

.Drawer-item {
    position: absolute;
    padding: 0;
    pointer-events: all;
    z-index: 1000;
    transform: translate(calc(40vmin - 50%), calc(-50%));

    width: 14vmin;
    height: 14vmin;
    text-align: center;
    vertical-align: middle;
    line-height: 14vmin;
    font-size: 7vmin;
    /*border: 0.1vmin solid white;*/
    border-radius: 14vmin;
    transition: all 0.4s;
    margin: auto;
    /*background: #ffffff10;*/
}

.Drawer-item:active {
    animation: Drawer-item-hover linear 0.6s forwards;
    /*background: #ffffff55;*/
}

@keyframes Drawer-item-hover {
    0% {
        background: transparent;
    }
    50% {
        background: #ffffff55;
    }
    100% {
        background: transparent;
    }
}
