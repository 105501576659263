:root {
    --danger-border-color: #282c34;
}

.App {
    text-align: center;
    background: #020202;
}

.App-header {
    background: #020202 url(../public/images/tiled-lines.png);
    background-size: 10vh auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    overflow: hidden;
    opacity: 1;
}
.App-header:after {
     content: '';
     background: transparent;
     position: absolute;
     min-height: 100vh;
     min-width: 100vw;
     box-shadow: inset 0 0 8vmin 4vmin var(--danger-border-color);
     opacity: 0.9;
 }

.time-ago {
    margin-top: calc(50vmin);
    opacity: 0.5;
    font-size: calc(4vmin);
}

.App-threat {
    opacity: 0;
    max-width: 100vw;
    max-height: 100vh;
    position: absolute;
    margin: auto;
    z-index: 1;
    font-size: calc(50vmin);
}
