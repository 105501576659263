@import url('https://fonts.googleapis.com/css?family=M+PLUS+1p|Orbitron:400,500&display=swap');

body {
  margin: 0;
  font-family: 'Orbitron', sans-serif;
  /*font-family: 'M PLUS 1p', sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  overflow-y: hidden;
  overflow-x: hidden;
  background: #020202;
}

* {
  user-select: none !important;
}
